import { Collapse, Divider, List, ListItemButton } from "@mui/material";
import * as React from "react";

import CompanyIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ReportsIcon from "@mui/icons-material/Feed";
import ReferralIcon from "@mui/icons-material/GroupAdd";
import HistoryIcon from "@mui/icons-material/History";
import CompaniesFinanceIcon from "@mui/icons-material/Money";
import TransactionIcon from "@mui/icons-material/Payments";
import CustomerIcon from "@mui/icons-material/People";
import PixIcon from "@mui/icons-material/Pix";
import VoucherIcon from "@mui/icons-material/Receipt";
import WithdrawalsIcon from "@mui/icons-material/RequestQuote";
import PurchaseIcon from "@mui/icons-material/ShoppingCart";
import TablesIcon from "@mui/icons-material/TableChart";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Link } from "react-router-dom";

const Sidebar: React.FC = () => {
  const [openReports, setOpenReports] = React.useState(false);
  const [openEntities, setOpenEntities] = React.useState(false);
  const [openVouchers, setOpenVouchers] = React.useState(false);
  const [openTransactions, setOpenTransactions] = React.useState(false);
  const [openWithdrawals, setOpenWithdrawals] = React.useState(false);

  const RenderEntitiesMenu: React.FC = () => {
    return (
      <React.Fragment>
        <ListItemButton onClick={() => setOpenEntities(!openEntities)}>
          <ListItemIcon>
            <TablesIcon />
          </ListItemIcon>
          <ListItemText primary="Registros" />
          {openEntities ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openEntities} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            <RenderEntitiesSubMenu />
          </List>
        </Collapse>
      </React.Fragment>
    );
  };
  const RenderEntitiesSubMenu: React.FC = () => {
    return (
      <React.Fragment>
        <MenuItem
          route={"/companies"}
          label={"Empresas"}
          icon={<CompanyIcon />}
        />

        <MenuItem
          route={"/customers"}
          label={"Clientes"}
          icon={<CustomerIcon />}
        />

        <ListItemButton onClick={() => setOpenVouchers(!openVouchers)}>
          <ListItemIcon>
            <VoucherIcon />
          </ListItemIcon>
          <ListItemText primary="Vouchers" />
          {openVouchers ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openVouchers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            <MenuItem
              route={"/vouchers"}
              label={"Cadastros"}
              icon={<VoucherIcon />}
            />
            <MenuItem
              route={"/vouchers/credits"}
              label={"Aprovações"}
              icon={<VoucherIcon />}
            />
          </List>
        </Collapse>

        <MenuItem
          route={"/purchases"}
          label={"Vendas"}
          icon={<PurchaseIcon />}
        />

        <ListItemButton onClick={() => setOpenTransactions(!openTransactions)}>
          <ListItemIcon>
            <TransactionIcon />
          </ListItemIcon>
          <ListItemText primary="Transações" />
          {openTransactions ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openTransactions} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            <MenuItem
              route={"/transactions/company"}
              label={"Empresa"}
              icon={<TransactionIcon />}
            />
            <MenuItem
              route={"/transactions/customer"}
              label={"Cliente"}
              icon={<TransactionIcon />}
            />
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const RenderMainMenu: React.FC = () => {
    return (
      <React.Fragment>
        <MenuItem
          route={"/dashboard"}
          label={"Dashboard"}
          icon={<DashboardIcon />}
        />
        <RenderReportsMenu />
        <Divider sx={{ my: 1 }} />
        <RenderWithdrawalsMenu />
      </React.Fragment>
    );
  };

  const RenderReportsMenu: React.FC = () => {
    return (
      <React.Fragment>
        <ListItemButton onClick={() => setOpenReports(!openReports)}>
          <ListItemIcon>
            <ReportsIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
          {openReports ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openReports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            <MenuItem
              route={"/reports/companies/finance"}
              label={"Empresas: Financeiro"}
              icon={<CompaniesFinanceIcon />}
            />
            <MenuItem
              route={"/reports/referrals"}
              label={"Indicações"}
              icon={<ReferralIcon />}
            />
            {/* import LastRecordsIcon from "@mui/icons-material/BackupTable"; */}
            {/* <ListItemButton component={Link} to="/reports/lastRecords">
              <ListItemIcon>
                <LastRecordsIcon />
              </ListItemIcon>
              <ListItemText primary="Últimos registros" />
            </ListItemButton> */}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const RenderWithdrawalsMenu: React.FC = () => {
    return (
      <React.Fragment>
        <ListItemButton onClick={() => setOpenWithdrawals(!openWithdrawals)}>
          <ListItemIcon>
            <WithdrawalsIcon />
          </ListItemIcon>
          <ListItemText primary="Saques" />
          {openWithdrawals ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openWithdrawals} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            <MenuItem
              route={"/withdrawals/pending"}
              label={"Pendentes"}
              icon={<PixIcon />}
            />
            <MenuItem
              route={"/withdrawals/history"}
              label={"Histórico"}
              icon={<HistoryIcon />}
            />
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const MenuItem: React.FC<{
    route: string;
    label: string;
    icon: React.ReactNode;
  }> = ({ route, label, icon }) => {
    return (
      <React.Fragment>
        <ListItemButton component={Link} to={route}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </React.Fragment>
    );
  };

  return (
    <List component="nav">
      <RenderMainMenu />
      <Divider sx={{ my: 1 }} />
      <RenderEntitiesMenu />
    </List>
  );
};

export default Sidebar;
