import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../../contexts/AuthContext";
import { services } from "../../../services/services";
import { FormatNumberToBRL } from "../../../services/utils/numberFormater";
import {
  ICompanyTransaction,
  ICompanyTransactionListResponse,
} from "../../../types/TransactionTypes";

const CompanyTransactionsView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [transactions, setTransactions] = useState<ICompanyTransaction[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.transactionService
        .listCompanyTransactions(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const transactionListResponse: ICompanyTransactionListResponse =
              response?.data;
            setTransactions(transactionListResponse.transactions);
            apiRef?.current?.autosizeColumns(autosizeOptions);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const apiRef = useGridApiRef();
  const tableColumns: GridColDef<(typeof transactions)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCompany",
      headerName: "Empresa",
      width: 250,
      type: "string",
    },
    {
      field: "nuCnpj",
      headerName: "CNPJ",
      type: "string",
    },
    {
      field: "dtTransaction",
      headerName: "Data da Transação",
      type: "string",
    },
    {
      field: "vlCredit",
      headerName: "Recebimento em Cashback",
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
    {
      field: "vlDebit",
      headerName: "Cashback à Pagar",
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
    {
      field: "flCompleted",
      headerName: "Efetivado?",
      type: "boolean",
    },
  ];

  const autosizeOptions = {
    columns: [
      "nmCompany",
      "nuCnpj",
      "dtTransaction",
      "vlCredit",
      "vlDebit",
      "flCompleted",
    ],
    includeOutliers: true,
    includeHeaders: true,
  };

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Transações", "Empresa"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            apiRef={apiRef}
            density="standard"
            columns={tableColumns}
            rows={transactions}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            autosizeOptions={autosizeOptions}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default CompanyTransactionsView;
