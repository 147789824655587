import { Box, Paper, Switch } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../contexts/AuthContext";
import { services } from "../../services/services";
import { FormatNumberToBRL } from "../../services/utils/numberFormater";
import { IDefaultResponse } from "../../types/CommonsTypes";
import { IWithdraw, IWithdrawListResponse } from "../../types/WithdrawTypes";

const WithdrawalsPendingView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [withdrawals, setWithdrawals] = useState<IWithdraw[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.withdrawService
        .listPendingWithdrawals(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const _response: IWithdrawListResponse = response?.data;
            setWithdrawals(_response?.withdrawals || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: GridColDef<(typeof withdrawals)[number]>[] = [
    {
      field: "nmCompany",
      headerName: "Parceiro",
      width: 350,
      type: "string",
    },
    {
      field: "dtRequested",
      headerName: "Data da Solicitação",
      width: 150,
      align: "right",
      type: "string",
      valueGetter: (value, row) => {
        if (moment(value).isValid()) {
          return moment(value).utcOffset(-6).format("DD/MM/YYYY HH:mm");
        }
      },
    },
    {
      field: "vlRequested",
      headerName: "Valor Solicitado",
      width: 150,
      type: "string",
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
    {
      field: "nuPix",
      headerName: "Chave PIX",
      width: 350,
      type: "string",
      sortable: false,
    },
    {
      field: "cdStatus",
      headerName: "Pagar?",
      width: 100,
      type: "string",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div>
          <Switch
            onChange={() => updateStatus(params.rowNode.id?.toString())}
            checked={params.value === "PAID"}
            disabled={isLoading}
          />
        </div>
      ),
    },
  ];

  const updateStatus = async (withdrawId: string) => {
    if (!isLoading) {
      try {
        setLoading(true);
        await services.withdrawService
          .changeStatus(userId, userToken, withdrawId)
          .then((response) => {
            if (response.status === 200) {
              const _resp: IDefaultResponse = response?.data;
              if (_resp.success) {
                loadData();
                setLoading(false);
              } else {
                console.log(`Erro ao Atualizar: ${_resp.message}`);
                setLoading(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Saques", "Solicitações Pendentes"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={withdrawals}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default WithdrawalsPendingView;
