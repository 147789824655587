import { Axios } from "axios";
import { WithdrawStatus } from "../../types/WithdrawTypes";
import { getHeaders } from "../RestClientService";

const API = {
  listWithdrawals: {
    url: () => {
      return `/withdrawals`;
    },
    method: "GET",
  },
  listWithdrawalsByStatus: {
    url: (status: string) => {
      return `/withdrawals/${status}`;
    },
    method: "GET",
  },
  changeStatus: {
    url: (id: string) => {
      return `/withdrawals/${id}`;
    },
    method: "PUT",
  },
};

export class WithdrawService {
  constructor(private axios: Axios) {}

  async listWithdrawals(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listWithdrawals.url(),
      method: API.listWithdrawals.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async listPendingWithdrawals(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listWithdrawalsByStatus.url(WithdrawStatus.Pending),
      method: API.listWithdrawalsByStatus.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async changeStatus(authToken: string, jwtToken: string, withdrawId: string) {
    return this.axios.request({
      url: API.changeStatus.url(withdrawId),
      method: API.changeStatus.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }
}
