import { IDefaultResponse } from "./CommonsTypes";

export const WithdrawStatus = {
  Pending: "PENDING",
  Paid: "PAID",
} as const;

export type WithdrawStatusType =
  (typeof WithdrawStatus)[keyof typeof WithdrawStatus];

export interface IWithdraw {
  id: number;
  nmCompany: string;
  dtRequested: string;
  vlRequested: number;
  nuPix: string;
  dtPayment: string;
  nmPaid: string;
  cdStatus: WithdrawStatusType;
}

export interface IWithdrawListResponse extends IDefaultResponse {
  withdrawals: [IWithdraw];
}
